import React from "react";

export const Datenschutz = () => {
  return (
    <div className="mx-auto my-20 max-w-7xl px-4 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-3xl text-lg ">
        <h1 className="font-bold">Datenschutzerklärung</h1>
        <h1 className="font-semibold mt-5">
          Allgemeines und Pflichtinformationen zum Datenschutz
        </h1>
        <p>
          Die Kanzlei Buchner behandelt Ihre personenbezogenen Daten vertraulich
          und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
          Datenschutzerklärung. Wenn Sie diese Website benutzen, werden
          verschiedene personenbezogene Daten erhoben. Personenbezogene Daten
          sind Daten, mit denen Sie persönlich identifiziert werden können. Die
          vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben
          und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck
          das geschieht. Wir weisen darauf hin, dass die Datenübertragung im
          Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken
          aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
          Dritte ist nicht möglich. Verantwortliche Stelle ist die natürliche
          oder juristische Person, die allein oder gemeinsam mit anderen über
          die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.
          B. Namen, E-Mail-Adressen o. Ä.) entscheidet. Die Verantwortliche
          Stelle bei uns lautet:
        </p>
        <br />
        <p>Benedikt Buchner</p>
        <p>Platzl 5</p>
        <p>80331 München</p>
        <p>buchner@buchner.legal</p>
        <h1 className="font-semibold mt-5">
          Widerruf Ihrer Einwilligung zur Datenverarbeitung
        </h1>
        <p>
          {" "}
          Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
          Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
          jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf
          erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
        </p>
        <h1 className="font-semibold mt-5">
          Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen (Art.
          21 DSGVO)
        </h1>
        <p>
          {" "}
          Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. e oder
          f DSGVO erfolgt, haben Sie jederzeit das Recht, aus Gründen, die sich
          aus ihrer besonderen Situation ergeben, gegen die Verarbeitung ihrer
          personenbezogenen Daten Widerspruch einzulegen (gilt auch für ein
          hierauf gestütztes Profiling). Wenn sie Widerspruch einlegen, werden
          wir ihre betroffenen personenbezogenen Daten nicht mehr verarbeiten,
          es sei denn, wir können zwingende schutzwürdige Gründe für die
          Verarbeitung nachweisen, die ihre Interessen, Rechte und Freiheiten
          überwiegen oder die Verarbeitung dient der Geltendmachung, Ausübung
          oder Verteidigung von Rechtsansprüchen (Widerspruch nach Art. 21 Abs.
          1 DSGVO).
        </p>
        <h1 className="font-semibold mt-5">
          Beschwerderecht bei der zuständigen Aufsichtsbehörde
        </h1>
        <p>
          {" "}
          Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
          Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige
          Aufsichtsbehörde ist das Bayerische Landesamt für Datenschutzaufsicht,
          Promenade 18, 91522 Ansbach (www.lda.bayern.de).
        </p>
        <h1 className="font-semibold mt-5">Recht auf Datenübertragbarkeit</h1>
        <p>
          Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
          oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
          oder an einen Dritten in einem gängigen, maschinenlesbaren Format
          aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an
          einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es
          technisch machbar ist.
        </p>
        <h1 className="font-semibold mt-5">SSL-Verschlüsselung</h1>
        <p>
          Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
          Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder
          Anfragen, die Sie an uns als Seitenbetreiber senden, eine
          SSL-Verschlüsselung. Wenn eine derartige Verschlüsselung aktiviert
          ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten
          mitgelesen werden.
        </p>
        <h1 className="font-semibold mt-5">
          Auskunft, Löschung und Berichtigung
        </h1>
        <p>
          {" "}
          Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
          das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
          personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck
          der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder
          Löschung dieser Daten.
        </p>
        <h1 className="font-semibold mt-5">
          Recht auf Einschränkung der Verarbeitung
        </h1>
        <p>
          {" "}
          Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Das Recht besteht: a, Wenn Sie
          die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten
          bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen.
          Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der
          Verarbeitung Ihrer personenbezogenen Daten zu verlangen. b, Wenn die
          Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
          geschah/geschieht, können Sie statt der Löschung die Einschränkung der
          Datenverarbeitung verlangen. c, Wenn wir Ihre personenbezogenen Daten
          nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder
          Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht,
          statt der Löschung die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. d, Wenn Sie einen Widerspruch
          nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen
          Ihren und unseren Interessen vorgenommen werden. Solange noch nicht
          feststeht, wessen Interessen überwiegen, haben Sie das Recht, die
          Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
          verlangen. Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
          eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung
          abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
          Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der
          Rechte einer anderen natürlichen oder juristischen Person oder aus
          Gründen eines wichtigen öffentlichen Interesses der Europäischen Union
          oder eines Mitgliedstaats verarbeitet werden.
        </p>
        <h1 className="font-semibold mt-5">Kontaktaufnahme</h1>
        <p>
          {" "}
          Wenn Sie uns per E-Mail, Telefon oder über das Kontaktformular
          kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden
          personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung
          Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben
          wir nicht ohne Ihre Einwilligung weiter. Die Verarbeitung beruht auf
          unserem berechtigten Interesse an der effektiven Bearbeitung der an
          uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
          Einwilligung (Art. 6 Abs. 1 lit. a DSGVO), sofern diese abgefragt
          wurde. Die von Ihnen an uns per Kontaktanfragen übersandten Daten
          verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre
          Einwilligung zur Speicherung widerrufen oder der Zweck für die
          Datenspeicherung entfällt. Zwingende gesetzliche Bestimmungen –
          insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.
        </p>
        <h1 className="font-semibold mt-5">Externes Hosting</h1>
        <p>
          Diese Website wird bei DigitalOcean, einem externen Dienstleister
          gehostet. Die personenbezogenen Daten, die auf dieser Website erfasst
          werden, werden auf den Servern des Hosters gespeichert. Hierbei
          handelt es sich v. a. um IP-Adressen, Kontaktanfragen, Meta- und
          Kommunikationsdaten, Kontaktdaten, Namen, Webseitenzugriffe und
          sonstige Daten, die über eine Website erzeugt werden. Der Einsatz des
          Hosters erfolgt zum Zwecke der Durchführung gegenüber unseren
          potenziellen und bestehenden Mitgliedern (Art. 6 Abs. 1 lit. b DSGVO)
          und im Interesse einer sicheren und effizienten Bereitstellung unseres
          Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1
          lit. f DSGVO). Unser Hoster wird Ihre Daten nur insoweit verarbeiten,
          wie dies zur Erfüllung seiner Leistungspflichten erforderlich ist und
          unsere Weisungen in Bezug auf diese Daten befolgen.
        </p>
        <h1 className="font-semibold mt-5">Widerspruch gegen Werbe-E-Mails</h1>
        <p>
          Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
          Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
          Werbung und Informationsmaterialien wird hiermit widersprochen. Die
          Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im
          Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
          Spam-E- Mails, vor.
        </p>
        <h1 className="font-semibold mt-5">Google Web Fonts</h1>​
        <p>
          {" "}
          Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
          genannte Web Fonts die von der Google Ireland Limited, Gordon House, 4
          Barrow St, Dublin, D04 E5W5, Irland („Google“) bereitgestellt werden.
          Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in
          ihren Browser-Cache, um Texte und Schriftarten korrekt anzuzeigen. Zu
          diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den
          Servern von Google aufnehmen. Hierbei kann es auch zu einer
          Übermittlung von personenbezogenen Daten an die Server der Google LLC.
          in den USA kommen. Auf diese Weise erlangt Google Kenntnis darüber,
          dass über Ihre IP-Adresse unsere Website aufgerufen wurde. Die
          Verarbeitung von personenbezogenen Daten im Zuge der
          Verbindungsaufnahme mit dem Anbieter der Schriftarten wird nur dann
          vollzogen, wenn Sie uns gemäß Art. 6 Abs. 1 lit. a DSGVO dazu Ihre
          ausdrückliche Einwilligung erteilt haben. Sie können Ihre erteilte
          Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen, indem
          Sie diesen Dienst in dem auf der Webseite bereitgestellten
          „Cookie-Consent-Tool“ deaktivieren. Wenn Ihr Browser Web Fonts nicht
          unterstützt, wird eine Standardschrift von Ihrem Computer genutzt.
          Weitere Informationen zu Google Web Fonts finden Sie unter
          https://developers.google.com/fonts/faq und in der
          Datenschutzerklärung von Google:
          https://www.google.com/policies/privacy/
        </p>
      </div>
    </div>
  );
};
