import React from "react";

export const Impressum = () => {
  return (
    <div className="mx-auto my-20 max-w-7xl px-4 sm:px-6 lg:px-8">
      {/* We've used 3xl here, but feel free to try other max-widths based on your needs */}
      <div className="mx-auto max-w-3xl text-lg ">
        <h1 className="font-bold mb-5">Impressum</h1>
        <h1 className="font-bold">
          Pflichtangaben gemäß § 5 Telemediengesetz (TMG)
        </h1>
        <h2 className="font-semibold mt-5">
          Verantwortliche für den Inhalt der Webseite und redaktionell
          Verantwortliche:
        </h2>
        <p>Rechtsanwalt Benedikt Buchner</p>
        <h2 className="font-semibold mt-5">Name und Anschrift:</h2>
        <p>Rechtsanwalt Benedikt Buchner, Platzl 5, 80331 München</p>
        <h2 className="font-semibold mt-5">Angabe der Aufsichtsbehörde:</h2>
        <p>
          Vorstand der Rechtsanwaltskammer für den OLG-Bezirk München, Tal 33,
          80331 München
        </p>

        <h2 className="font-semibold mt-5">
          Berufsbezeichnung und zuständige Kammer:
        </h2>
        <p>
          Benedikt Buchner ist als Rechtsanwalt in der Bundesrepublik
          Deutschland zugelassen und gehört der Rechtsanwaltskammer für den
          OLG-Bezirk München an.
        </p>

        <h2 className="font-semibold mt-5">
          Bezeichnung der berufsrechtlichen Regelungen:
        </h2>

        <ul className="list-disc">
          <li>Bundesrechtsanwaltsordnung (BRAO)</li>
          <li>Berufsordnung der Rechtsanwälte (BORA)</li>
          <li>Fachanwaltsordnung (FAO)</li>
          <li>Rechtsanwaltsvergütungsgesetz (RVG)</li>
          <li>
            Berufsregeln der Rechtsanwälte der Europäischen Gemeinschaft (CCBE)
          </li>
          <li>
            Gesetz über die Tätigkeit europäischer Rechtsanwälte in Deutschland
            (EuRAG)
          </li>
        </ul>
        <p>
          Die oben genannten Regelungen sind auf der Homepage der
          Bundesrechtsanwaltskammer unter “Berufsrecht” abrufbar unter: <br />
          <a href="http://www.brak.de/fuer-anwaelte/berufsrecht">
            http://www.brak.de/fuer-anwaelte/berufsrecht
          </a>
          .
        </p>
        <h2 className="font-semibold mt-5">
          Wir vertreten und beraten bundesweit.
        </h2>
      </div>
    </div>
  );
};
