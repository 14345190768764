import React from "react";
import "./hero.scss";

import logo from "../../images/logos/logo.png";

export const Hero = () => {
  return (
    <div className="hero">
      <div className="hero-content">
        <div className="img">
          <img src={logo} alt="" srcset="" />
        </div>
        <h1 id="header">Buchner</h1>
        <h1>Rechtsanwaltskanzlei</h1>
        <h2>Rechtsanwalt Benedikt Buchner</h2>
        <h3>Immobilien | Private Clients | Family Offices | Consilierie</h3>
        <h3>Platzl 5, 80331 München</h3>

        <h4>Hinweis: Die Website befindet sich vorübergehend im Umbau.</h4>
      </div>
    </div>
  );
};
