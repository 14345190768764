import React from "react";
import benedikt from "../images/screenshots/benedikt.jpg";

const people = [
  {
    name: "Benedikt Buchner, B.Sc. (BWL, LMU)",
    role: "Rechtsanwalt",
    imageUrl: benedikt,
    bio: "Meine Expertise liegt in der Beratung im Bereich des Immobilienrechts sowie der Vermögens- und Unternehmensoptimierung und Nachfolge. Ich biete eine ganzheitliche Beratung von Familienunternehmen inklusive Erbfolge an. Des Weiteren umfasst mein Leistungsspektrum die umfassende Beratung und Vertretung von Objektgesellschaften sowie die Begleitung von Transaktionen und gesellschaftsrechtliche Gestaltung.",
  },
];

export const Lawyer = () => {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className=" max-w-2xl">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-left">
            RA Benedikt Buchner
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Hier finden Sie heraus, wer aktuell hinter BUCHNER.LEGAL steckt.
            More to be announced soon...
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-2 lg:max-w-4xl lg:gap-x-8 xl:max-w-none"
        >
          {people.map((person) => (
            <li key={person.name} className="flex flex-col gap-6 xl:flex-row">
              <img
                className="aspect-[4/5] w-52 flex-none rounded-2xl object-cover"
                src={person.imageUrl}
                alt=""
              />
              <div className="flex-auto">
                <h3 className="text-lg font-semibold leading-8 tracking-tight text-gray-900">
                  {person.name}
                </h3>
                <p className="text-base font-bold leading-7 text-gray-600">
                  {person.role}
                </p>
                <p className="mt-6 text-base leading-7 text-gray-600">
                  {person.bio}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
