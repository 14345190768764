import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Nav } from "./components/nav";
import { Landing } from "./pages/landing";
import { Kanzlei } from "./components/firm/firm";
import { Lawyer } from "./components/lawyers";
import { Kontakt } from "./components/contact";
import { Datenschutz } from "./pages/datenschutz";
import { Impressum } from "./pages/impressum";
import { Footer } from "./components/footer";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Nav />
        <Routes>
          <Route path={"/"} element={<Landing />} />
          <Route path={"/kanzlei"} element={<Kanzlei />} />
          <Route path={"/kanzlei"} element={<Kanzlei />} />
          <Route path={"/anwalt"} element={<Lawyer />} />
          <Route path={"/kontakt"} element={<Kontakt />} />
          <Route path={"/datenschutz"} element={<Datenschutz />} />
          <Route path={"/impressum"} element={<Impressum />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;

// Foto von EKATERINA  BOLOVTSOVA: https://www.pexels.com/de-de/foto/holzern-gericht-richter-legal-6077326/
// Foto Platzl: Berthold Werner - under CC BY-SA 3.0
