import React from "react";
import { Hero } from "../components/hero/hero";

export const Landing = () => {
  return (
    <div>
      <Hero />
    </div>
  );
};
