// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/screenshots/stuck.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".firm {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: center center;\n}\n\n.content {\n  background-color: #fff;\n  padding: 3rem;\n  opacity: 0.8;\n}", "",{"version":3,"sources":["webpack://./src/components/firm/firm.scss"],"names":[],"mappings":"AAAA;EACE,yDAAA;EACA,kCAAA;AACF;;AAEA;EACE,sBAAA;EACA,aAAA;EACA,YAAA;AACF","sourcesContent":[".firm {\n  background-image: url(\"../../images/screenshots/stuck.jpg\");\n  background-position: center center;\n}\n\n.content {\n  background-color: #fff;\n  padding: 3rem;\n  opacity: 0.8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
